import React, {Component} from 'react';
import { Link } from "react-router-dom";

import './Header.scss';

const contentful = require('contentful');

class Header extends Component{
    constructor(props) {
        super(props);
  
        this.state = {
            error: null,
            isLoaded: false,
            header: []
        };
    }

    toggleMobileMenu(){

        const menuIcon = document.querySelector('.menu-icon');
        const menuOverlay = document.querySelector('.site-menu');

        const menuActiveClass = "site-menu--active";
        const iconActiveClass = "menu-icon--active";

        if (menuOverlay.classList.contains(menuActiveClass) ){
            menuIcon.classList.remove(iconActiveClass);
            menuOverlay.classList.remove(menuActiveClass);
        } else {
            menuOverlay.classList.add(menuActiveClass);
            menuIcon.classList.add(iconActiveClass);
        }
    }


    componentDidMount(){

        const client = contentful.createClient({
            space: 'wz6wklh29qn3',
            accessToken: '0nVoRYo-NO7ZQpvPsVSsWthLiGF-GiHoqUrkPcOtheo'
        })

        client.getEntry('2pAuAHfZr5ehRE8BqGStO2')
        .then((headerData) => {
        
            this.setState({
                header: headerData.fields,
                isLoaded: true
            })  
        })
    }



    render(){
        const { error, isLoaded, header } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return ( 
                <div className="loading">
                    Loading...
                </div>
                );
        } else {
            return(
                <div className="site-header">
                    <div className="site-wrapper">
                        <Link to="/">
                            <img className="site-header__logo" src={header.logo.fields.file.url} alt={header.title} />
                        </Link>

                        <button className="menu-icon" onClick={this.toggleMobileMenu.bind(this)}>
                            <div className="menu-icon__bar"></div>
                        </button>
                    </div>
                </div>
            )
        }
    }
}

export default Header;