import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Loading from './Loading';
import PropertyCard from './PropertyCard';

import './Properties.scss';

const contentful = require('contentful');

class Properties extends Component{

    constructor(props) {
        super(props);
  
        this.state = {
            error: null,
            isLoaded: false,
            isFeatured: false,
            properties: []
        };
    }

    componentDidMount(){
        const client = contentful.createClient({
            space: 'wz6wklh29qn3',
            accessToken: '0nVoRYo-NO7ZQpvPsVSsWthLiGF-GiHoqUrkPcOtheo'
        })

        client.getEntries({
            'content_type': 'properties'
        })
        .then((propertiesData) => {
            this.setState({
                properties: propertiesData.items,
                isLoaded: true
            })  
        })
    }

    render() {

        const { error, isLoaded, properties } = this.state;

        console.log(properties)

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return ( 
                <Loading />
            );
        } else {
            return (
            <TransitionGroup>
                <CSSTransition
                  classNames="fade"
                  timeout={700}
                >
                    <div className="properties">
                        <div className="banner properties__banner" style={{backgroundImage: `url(https://placehold.it/1440x900)`}}>
                            <div className="site-wrapper">
                                <div className="banner__content">
                                    <h1>Properties</h1>
                                </div>
                            </div>
                        </div>
                        <div className="properties__list">
                            <div className="site-wrapper">
                            {properties.map(indvProperty =>(
                                <PropertyCard key={indvProperty.sys.id} propertyId={indvProperty.sys.id}/>
                            ))}
                            </div>
                        </div>

                    </div>
                    
                </CSSTransition>
            </TransitionGroup>
            );
        }
    }
}

export default Properties;