import React, { Component } from 'react';

class Loading extends Component{

    constructor(props) {
        super(props);
  
        this.state = {
            error: null
        };
    }

    render(){
        return (
            <div className="loading">Loading...</div>
        )
    }

}

export default Loading;