import React, { Component } from 'react';
import Loading from './Loading';

import './About.scss';

const contentful = require('contentful');

class Page extends Component{

    constructor(props) {
        super(props);
  
        this.state = {
            error: null,
            isLoaded: false,
            about: [],
            entryId: props.entryId
        };
    }

    componentDidMount(){
        const client = contentful.createClient({
            space: 'wz6wklh29qn3',
            accessToken: '0nVoRYo-NO7ZQpvPsVSsWthLiGF-GiHoqUrkPcOtheo'
        })

        client.getEntry(this.state.entryId)
        .then((pageData) => {
        
            this.setState({
                page: pageData.fields,
                isLoaded: true
            })  
        })
    }

    render() {
        const { error, isLoaded, page } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return ( 
                <Loading />
            );
        } else {
            return (
                <div className="page about">

                <div className="banner about__banner" style={{backgroundImage: `url(https://placehold.it/1440x900)`}}>
                    <div className="site-wrapper">
                        <div className="banner__content">
                            <h1>{page.title}</h1>
                        </div>
                    </div>
                </div>

            </div>
            );
        }
    }
}

export default Page;