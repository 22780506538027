import React, {Component} from 'react';
import { Link } from "react-router-dom";

class MenuOverlay extends Component{
    constructor(props) {
        super(props);
  
        this.state = {
            error: null,
            isLoaded: false
        };
    }

    closeMenu(){
        
        const menuIcon = document.querySelector('.menu-icon');
        const menuOverlay = document.querySelector('.site-menu');

        const menuActiveClass = "site-menu--active";
        const iconActiveClass = "menu-icon--active";

        menuIcon.classList.remove(iconActiveClass);
        menuOverlay.classList.remove(menuActiveClass);
    }

    componentDidMount(){
        
    }

    render(){
        
        return(
            <div className="site-menu">
                <div className="site-menu__overlay" onClick={this.closeMenu.bind(this)}></div>
                <div className="site-menu__panel">
                    <nav className="site-nav">
                        <Link to="/" onClick={this.closeMenu.bind(this)}>Home</Link>
                        <Link to="/properties" onClick={this.closeMenu.bind(this)}>Properties</Link>
                    </nav>
                    <div className="site-menu__footer">
                        <nav>
                            <Link to="/privacy" onClick={this.closeMenu.bind(this)}>Privacy</Link>
                            <Link to="/accessibility" onClick={this.closeMenu.bind(this)}>Accessibility</Link>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
}

export default MenuOverlay;