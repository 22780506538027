import React, { Component } from 'react';
import Loading from './Loading';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import './About.scss';

const contentful = require('contentful');

class Accessibility extends Component{

    constructor(props) {
        super(props);
  
        this.state = {
            error: null,
            isLoaded: false,
            page: []
        };
    }

    componentDidMount(){
        const client = contentful.createClient({
            space: 'wz6wklh29qn3',
            accessToken: '0nVoRYo-NO7ZQpvPsVSsWthLiGF-GiHoqUrkPcOtheo'
        })

        client.getEntry('39pPmSRvardHJI3V84CAjF')
        .then((pageData) => {

            const richTextContent = pageData.fields.content;
            const renderedContent = documentToReactComponents(richTextContent);

            this.setState({
                page: pageData.fields,
                isLoaded: true,
                content: renderedContent
            })  
        })
    }

    render() {
        const { error, isLoaded, page, content } = this.state;
        
        console.log(page, content);

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return ( 
                <Loading />
            );
        } else {
            return (
                <div className="page about">

                <div className="banner about__banner" style={{backgroundImage: `url(https://placehold.it/1440x900)`}}>
                    <div className="site-wrapper">
                        <div className="banner__content">
                            <h1>{page.title}</h1>
                        </div>
                    </div>
                </div>

                <div className="site-wrapper">
                    <div className="content-wrapper">
                        {content.map(contentBlock =>(
                            <div key={contentBlock.key}>

                                {contentBlock.type === "p" &&
                                    <p>
                                        {contentBlock.props.children}
                                    </p>
                                }
                                {contentBlock.type === "h1" &&
                                    <h1>
                                        {contentBlock.props.children}
                                    </h1>
                                }   
                                {contentBlock.type === "h2" &&
                                    <h2>
                                        {contentBlock.props.children}
                                    </h2>
                                }  
                                {contentBlock.type === "h3" &&
                                    <h3>
                                        {contentBlock.props.children}
                                    </h3>
                                }  
                                {contentBlock.type === "h4" &&
                                    <h4>
                                        {contentBlock.props.children}
                                    </h4>
                                }
                                {contentBlock.type === "h3" &&
                                    <h5>
                                        {contentBlock.props.children}
                                    </h5>
                                }
                                {contentBlock.type === "ul" &&
                                    <ul>
                                        {contentBlock.props.children}
                                    </ul>
                                } 
                                
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            );
        }
    }
}

export default Accessibility;