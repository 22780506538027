import React, {Component} from 'react';
import { Link } from "react-router-dom";

import IconStar from './images/star.svg';

import './PropertyCard.scss';

const contentful = require('contentful');


class PropertyCard extends Component{

    constructor(props) {
        super(props);
  
        this.state = {
            error: null,
            isLoaded: false,
            propertyId: props.propertyId,
            propertyCard: []
        };
    }

    componentDidMount(){

        const client = contentful.createClient({
            space: 'wz6wklh29qn3',
            accessToken: '0nVoRYo-NO7ZQpvPsVSsWthLiGF-GiHoqUrkPcOtheo'
        })

        client.getEntry(this.state.propertyId)
        .then((propertyCardData) => {
        
            this.setState({
                propertyCard: propertyCardData.fields,
                isLoaded: true
            })  
        })
    }

    render() {
        const { error, isLoaded, propertyCard } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return ( 
                <div className="loading">
                    Loading...
                </div>
                );
        } else {
            return (

                <Link className={"property-card" + (propertyCard.comingToMarket === true ? ' coming-to-market' : '')} to={{
                    pathname: `/properties${propertyCard.url}`
                }}>
                    <div className="property-card__content">
                        <h4>{propertyCard.address}</h4>
                        <h5 className="badge">{ propertyCard.city }</h5>
                    </div>

                    {propertyCard.comingToMarket === true &&
                        <div className="coming-to-market__banner">
                            <img src={IconStar} alt="" /><h5>Available Now</h5>
                        </div>
                    }

                    <picture className="banner__img">
                        <source media="(max-width: 499px)" srcSet={propertyCard.featuredImage.fields.file.url + "?w=300&fm=webp&q=90&"} />
                        <source media="(max-width: 999px)" srcSet={propertyCard.featuredImage.fields.file.url + "?w=500&fm=webp&q=90"} />
                        <source media="(min-width: 1000px)" srcSet={propertyCard.featuredImage.fields.file.url + "?w=600&fm=webp&q=90"} />
                        <img src={propertyCard.featuredImage.fields.file.url + "?w=500&fl=progressive&q=50"} alt={propertyCard.title} />
                    </picture>
                </Link>

            );
        }
    }
}

export default PropertyCard;