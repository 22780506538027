import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

import Pin from './images/pin.svg';

const MapPin = () => <img src={Pin} alt=""/>;

const mapOptions = {
    styles: [
        {
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "gamma": 1
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi.business",
            "elementType": "labels.text",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi.business",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi.place_of_worship",
            "elementType": "labels.text",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi.place_of_worship",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "water",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "saturation": 50
                },
                {
                    "gamma": 0
                },
                {
                    "hue": "#50a5d1"
                }
            ]
        },
        {
            "featureType": "administrative.neighborhood",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#333333"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text",
            "stylers": [
                {
                    "weight": 0.5
                },
                {
                    "color": "#333333"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "gamma": 1
                },
                {
                    "saturation": 50
                }
            ]
        }
    ]    
};

class GoogleMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            latitude: props.lng,
            longitude: props.lat,
            center: props.center
        };
    }

    static defaultProps = {
        center: {
            lat: 49.9,
            lng: -97.105
        },
        zoom: 14.5
    };

    render() {

        const {longitude, latitude, center } = this.state;

        return (
            // Important! Always set the container height explicitly
            <div className="map" style={{ height: '60vh', width: '100%' }}>
                <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyAh7BLUhzLyNuCKeQMobFEXiI4imfY4qtw' }}
                defaultCenter={center}
                defaultZoom={this.props.zoom}
                options={mapOptions}
                >
                <MapPin
                    lat={latitude}
                    lng={longitude}
                />
                </GoogleMapReact>
            </div>
        );
    }
}

export default GoogleMap;