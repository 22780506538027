import React, {Component} from 'react';
import { Link } from "react-router-dom";

import './Footer.scss';


const contentful = require('contentful');

class Footer extends Component{
    constructor(props) {
        super(props);
  
        this.state = {
            error: null,
            isLoaded: false,
            footer: []
        };
    }

    componentDidMount(){

        const client = contentful.createClient({
            space: 'wz6wklh29qn3',
            accessToken: '0nVoRYo-NO7ZQpvPsVSsWthLiGF-GiHoqUrkPcOtheo'
        })

        client.getEntry('2pAuAHfZr5ehRE8BqGStO2')
        .then((footerData) => {
        
            this.setState({
                footer: footerData.fields,
                isLoaded: true
            })  
        })
    }

    render(){
        const { error, isLoaded, footer } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return ( 
                <div className="loading">
                    Loading...
                </div>
                );
        } else {
            return(
                <div className="site-footer">
                    <div className="site-wrapper">

                        <img className="site-footer__logo" src={footer.logoFooter.fields.file.url} alt={footer.title} />

                        <nav>
                            <Link to="/">Home</Link>
                            <Link to="/properties">Properties</Link>
                            {/* <Link to="/about">About</Link> */}
                        </nav>

                        <div className="site-footer__meta">
                            <nav>
                                <Link to="/privacy">Privacy Policy</Link>
                                <Link to="/accessibility">Accessibility</Link>
                            </nav>
                        </div>

                        <div className="site-footer__copyright">
                            <p>&copy; {(new Date().getFullYear())} - Cinch Property Services - All rights reserved</p>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Footer;