import React, { Component } from 'react';
import Loading from './Loading';

class NewsletterSignup extends Component{

    constructor(props) {
        super(props);
  
        this.state = {
            error: null,
            isLoaded: false
        };
    }

    componentDidMount(){
        
        this.setState({
            isLoaded: true
        })  
    }

    render() {
        const { error, isLoaded } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return ( 
                <Loading />
            );
        } else {
            return (
                <div className="newsletter">
                    <div className="site-wrapper">
                        <div className="content-wrapper">
                            <h4>Newsletter</h4>
                            <p>Stay up to date with our latest developments and get notified when units are available for lease.</p>

                            <div id="mc_embed_signup">
                                <form action="https://property.us4.list-manage.com/subscribe/post?u=3eea2b6d064ab254d96af97e6&amp;id=bdb09f96ee" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                    <div className="grid">
                                
                                        <div className="grid__item" data-col="two-thirds">
                                            <label htmlFor="mce-EMAIL">Email Address </label>
                                            <input type="email" defaultValue="" name="EMAIL" className="required email" id="mce-EMAIL" />
                                        </div>

                                        <div style={{position: "absolute", left: -5000 + "px"}} aria-hidden="true">
                                            <input type="text" name="b_3eea2b6d064ab254d96af97e6_bdb09f96ee" tabIndex="-1" defaultValue="" />
                                        </div>
                                        <div className="grid__item" data-col="one-third">
                                            <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default NewsletterSignup;