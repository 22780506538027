import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Header from './Header.js';
import Footer from './Footer';
import MenuOverlay from './MenuOverlay.js';
import NewsletterSignup from './NewsletterSignup';
import ScrollToTop from './ScrollToTop';

import Home from './Home';
import About from './About';
import Privacy from './Privacy.js';
import Accessibility from './Accessibility.js';
import Properties from './Properties';
import Property from './Property';

import 'normalize.css';
import './App.scss';

function App({match}) {

  return (
    
    <Router>
      <ScrollToTop>
        <div className="site">

          <Header />

          <MenuOverlay />
          
          <Switch>
            
            <Route exact path="/" component={Home} />
            <Route exact path="/properties" component={Properties} />
            <Route exact path="/properties/:property" component={Property} />
            <Route path="/about" entryId="" component={About} />
            <Route path="/privacy" entryId="4NSOmVP5HjMYKqbVWCuwLw" component={Privacy} />
            <Route path="/accessibility" entryId="39pPmSRvardHJI3V84CAjF" component={Accessibility} />
            <Route path="/thank-you" entryId="" component={About} />

            <Route render={() => <div>Not Found</div>} />

          </Switch>

          
          <NewsletterSignup />

          <Footer />

        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
