import React, {Component} from 'react';
import PropertyCard from './PropertyCard';
import './Home.scss';

const contentful = require('contentful');

class Home extends Component {

    constructor(props) {
        super(props);
  
        this.state = {
            error: null,
            isLoaded: false,
            home: []
        };
    }

    componentDidMount(){

        const client = contentful.createClient({
            space: 'wz6wklh29qn3',
            accessToken: '0nVoRYo-NO7ZQpvPsVSsWthLiGF-GiHoqUrkPcOtheo'
        })

        client.getEntry('1dNkjJF0oa0db9eyfIQSiN')
        .then((homeData) => {
        
            this.setState({
                home: homeData.fields,
                isLoaded: true
            })  
        })
    }

    render() {
        const { error, isLoaded, home } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return ( 
                <div className="loading">
                    Loading...
                </div>
                );
        } else {
            return (
         
                <div className="home">

                    <div className="home-banner banner">
                        <picture className="banner__img">
                            <source media="(max-width: 499px)" srcSet={home.bannerImage.fields.file.url + "?w=850&fm=webp&q=65"} />
                            <source media="(max-width: 999px)" srcSet={home.bannerImage.fields.file.url + "?w=1120&fm=webp&q=80"} />
                            <source media="(min-width: 1000px)" srcSet={home.bannerImage.fields.file.url + "?w=1440&fm=webp&q=90"} />
                            <img src={home.bannerImage.fields.file.url + "?w=1440&fl=progressive&q=50"} alt={home.bannerCaption} />
                        </picture>

                        <div className="site-wrapper">
                            <div className="banner__content">
                                <h2>{home.bannerTitle}</h2>
                                <p>{home.bannerBlurb}</p>
                                <span className="banner__caption">{home.bannerCaption}</span>
                            </div>
                        </div>
                    </div>

                    <div className="featured-properties">
                        <div className="site-wrapper">
                            <h3>Some of our featured projects</h3>
                            {home.featuredProperties.map(featuredProperty =>(
                                <PropertyCard key={featuredProperty.sys.id} propertyId={featuredProperty.sys.id} />
                            ))}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Home;
