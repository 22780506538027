import React, { Component } from 'react';
import Loading from './Loading';
import GoogleMap from './GoogleMap';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import './Property.scss';


const contentful = require('contentful');

class Property extends Component{

    constructor(props) {
        super(props);

        const windowUrl = window.location.href.split('/')
        const propId = "/"+windowUrl[windowUrl.length - 1]
  
        this.state = {
            error: null,
            isLoaded: false,
            propertyUrl: propId,
            property: []
        };
    }

    componentDidMount(){

        const client = contentful.createClient({
            space: 'wz6wklh29qn3',
            accessToken: '0nVoRYo-NO7ZQpvPsVSsWthLiGF-GiHoqUrkPcOtheo'
        })

        client.getEntries({
            "content_type": "properties",
            "fields.url": this.state.propertyUrl
        })
        .then((propertyData) => {

            console.log(propertyData)
            
            const richTextContent = propertyData.items[0].fields.description;

            const renderedContent = documentToReactComponents(richTextContent); 
        
            this.setState({
                property: propertyData.items[0].fields,
                isLoaded: true,
                content: renderedContent,
                long: (propertyData.items[0].fields.coordinates.split(',')[0] * 1),
                lat: (propertyData.items[0].fields.coordinates.split(',')[1] * 1),
                featuredImage: propertyData.items[0].fields.featuredImage.fields.file.url,
                gallery: propertyData.items[0].fields.gallery
            })  
        })
    }
        


    render() {
        const { error, isLoaded, property, content, long, lat, featuredImage, gallery } = this.state;

        console.log(gallery);

        var center =[long, lat];

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return ( 
                <Loading />
            );
        } else {
            return (
                <div className="property">
                    <div className="banner property-banner">

                        <picture className="banner__img">
                            <source media="(max-width: 499px)" srcSet={featuredImage + "?w=850&fm=webp&q=80"} />
                            <source media="(max-width: 999px)" srcSet={featuredImage + "?w=1120&fm=webp&q=80"} />
                            <source media="(min-width: 1000px)" srcSet={featuredImage + "?w=1440&fm=webp&q=90"} />
                            <img src={featuredImage + "?w=1440&fl=progressive&q=50"} alt={property.title} />
                        </picture>

                        <div className="site-wrapper">
                            <div className="banner__content">
                                <h2>{property.address}</h2>
                                <p>{property.city}, {property.country}</p>
                            </div>
                        </div>
                    </div>
                    <div className="property__details">
                        <div className="site-wrapper property-guts">
                            <div className="content-wrapper">
                                <h1>{property.title}</h1>

                                {content.map(contentBlock =>(
                                    <div key={contentBlock.key}>
                                        <p>{contentBlock.props.children}</p>
                                    </div>
                                ))}

                                <div className="gallery">
                                    {gallery.map(image => (
                                        <picture className="gallery__thumb" key={image.sys.id}>
                                            <source media="(max-width: 998px)" srcSet={image.fields.file.url + "?w=550&fm=webp&q=80"} />
                                            <source media="(min-width: 999px)" srcSet={image.fields.file.url + "?w=1200&fm=webp&q=100"} />

                                            <img src={image.fields.file.url + "?w=200&fm=webp&q=85"} alt={image.fields.title} />
                                        </picture>
                                    ))}
                                </div>
                               
                            </div>
                            {property.comingToMarket === true &&
                                    <></>
                                }
                            </div>

                        <GoogleMap lat={lat} lng={long} center={center}/>

                    </div>
                </div>
            );
        }
    }
}

export default Property;